import * as React from "react";
import {Logo} from './Logo';
import {HamburgerMenuButton} from './HamburgerMenuButton';

export interface HeaderProps { }

export const Header = (props: HeaderProps) => {

    return (  
        <div className="header">
            <div className="logoPart">
                <Logo />
            </div>
            <div className="titlePart">
                    <div className="title">Scalebit</div>
                    <div className="subtitle">A Software Engineering Consultant Company</div>
            </div>
            <div className="menuPart">
                <HamburgerMenuButton />
            </div>
        </div>   
    )
};