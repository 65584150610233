import * as React from "react";
import MenuIcon from "../../grfx/hamburger-menu-icon.svg";
import {useDispatch, useSelector} from "react-redux";
import {AppActionNames, AppState, ComponentVisibility} from "../redux/AppStore";

export interface SlideInMenuProps { }

export const SlideInMenu = (props: SlideInMenuProps) => {

    const slideInMenuVisible = useSelector((state: AppState) => state.slideInMenuVisibility);
    const dispatch = useDispatch();

    

    const visibilityClassName = (() => {
        switch(slideInMenuVisible) {
            case ComponentVisibility.Hidden:
                return "slideInMenuHidden";
            case ComponentVisibility.Visible:
                return "slideInMenuVisible";
            default:
                throw new Error(`unknown slidemenu visibility '${slideInMenuVisible}'`);
        }
    })();

    const coverPaneClick = () => {
        dispatch({type: AppActionNames.HideSlideInMenu});
    }

    return (
        <>
            <div className={`slideInMenuGlassPane slideInMenuGlassPane${slideInMenuVisible}`} onClick={coverPaneClick}>
            </div>  
            <div className={"slideInMenu " + visibilityClassName}>
                <button>About</button>
                <button>Services</button>
                <button>Profiles</button>
                <button>Contact</button>
            </div>
        </> 
    )
};