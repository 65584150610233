import * as React from "react";
import {Logo} from './Logo'
import {Header} from './Header'
import {UnderConstruction} from './UnderConsruction'
import {SlideInMenu} from './SlideInMenu'

export interface AppProps { }

export const App = (props: AppProps) => {

    return (
            <>
                <Header />
                <SlideInMenu />  
                <div className="frontPage">                    
                    <div className="page2" style={{marginTop: "150px"}} >
                        <a id="page2" href="#">&nbsp;</a>
                        <UnderConstruction />
                        Under Construction
                    </div>
                </div>
            </> 
    )
};