import * as React from "react";
import MenuIcon from "../../grfx/hamburger-menu-icon.svg";
import {useDispatch, useSelector} from "react-redux";
import {AppActionNames, AppState, ComponentVisibility} from "../redux/AppStore";

export interface HamburgerMenuButtonProps { }

export const HamburgerMenuButton = (props: HamburgerMenuButtonProps) => {

    const dispatch = useDispatch();
    const slideInMenuVisible = useSelector((state: AppState) => state.slideInMenuVisibility);

    const onClick = () => {

        switch(slideInMenuVisible) {
            case ComponentVisibility.Visible:
                dispatch({type:AppActionNames.HideSlideInMenu});
                break;
            case ComponentVisibility.Hidden:
                dispatch({type:AppActionNames.ShowSlideInMenu});
                break;
            default:
                throw new Error(`unkown visibility: '${slideInMenuVisible}'`);
        }

    }

    return (  
        <img className="hamburgerMenuButton" src={MenuIcon} onClick={onClick}/>
    )
};