import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, combineReducers} from 'redux';
import { App } from "./components/App";
import '../style/index.scss';
import {createAppStore} from "./redux/AppStore";

//const rootReducers = combineReducers({app: appReducer});

const store = createAppStore();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById("app")
);