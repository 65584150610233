import {Action, Reducer, createStore} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

export enum ComponentVisibility {
    Visible = "Visible",
    Hidden = "Hidden"
}

export interface AppState {
    slideInMenuVisibility: ComponentVisibility;
}

export enum AppActionNames {
    ShowSlideInMenu = '[@slideInMenu/show]',
    HideSlideInMenu = '[@slideInMenu/hide]',
}

interface ShowSlideInMenuAction extends Action<AppActionNames> {}
interface HideSlideInMenuAction extends Action<AppActionNames> {}

export type AppActionTypes = 
      ShowSlideInMenuAction 
    | HideSlideInMenuAction


const initalAppState: AppState = {
    slideInMenuVisibility: ComponentVisibility.Hidden,
}

export const appStateReducer: Reducer<AppState, AppActionTypes> = (state = initalAppState, action) => {
    switch(action.type) {
        case AppActionNames.ShowSlideInMenu:
            return {
                ...state,
                slideInMenuVisibility: ComponentVisibility.Visible,
                coverPaneVisibility: ComponentVisibility.Visible
            }
        case AppActionNames.HideSlideInMenu:
            return {
                ...state,
                slideInMenuVisibility: ComponentVisibility.Hidden,
                coverPaneVisibility: ComponentVisibility.Hidden
            }
        default:
            return state;       
    }
}

export const createAppStore = () => {
    return createStore<AppState, AppActionTypes, null, null>(appStateReducer, composeWithDevTools());
}
